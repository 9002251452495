import Vue from 'vue'
import VueRouter from 'vue-router'

const OverlayAlert = () => import(/* webpackChunkName: "overlays-chunk" */'../views/Overlays/Alert')
const OverlayGoal = () => import(/* webpackChunkName: "overlays-chunk" */'../views/Overlays/Goal')
const OverlayLatestDonator = () => import(/* webpackChunkName: "overlays-chunk" */'../views/Overlays/LatestDonator')
const OverlayLastTen = () => import(/* webpackChunkName: "overlays-chunk" */'../views/Overlays/LastTen')
const OverlayRanking = () => import(/* webpackChunkName: "overlays-chunk" */'../views/Overlays/Ranking')
const OverlayQrcode = () => import(/* webpackChunkName: "overlays-chunk" */'../views/Overlays/Qrcode')

const OverlayTipa = () => import(/* webpackChunkName: "overlays-chunk" */'../views/OverlayTipa')

const ElementsOverlay = () => import(/* webpackChunkName: "overlays-chunk" */'../views/ElementsOverlay')

Vue.use(VueRouter)

const routesOverlay = [
  {
    path: '/users/:id/overlay',
    name: 'OverlayAlert',
    component: OverlayAlert,
    meta: { layout: 'overlay' }
  },
  {
    path: '/users/:id/qrcode',
    name: 'OverlayQrcode',
    component: OverlayQrcode,
    meta: { layout: 'overlay' }
  },
  {
    path: '/users/:user_id/goals/:id',
    name: 'OverlayGoal',
    component: OverlayGoal,
    meta: { layout: 'overlay' }
  },
  {
    path: '/users/:user_id/overlays/latest',
    name: 'OverlayLastTen',
    component: OverlayLastTen,
    meta: { layout: 'overlay' }
  },
  {
    path: '/users/:user_id/overlays/ranking',
    name: 'OverlayRanking',
    component: OverlayRanking,
    meta: { layout: 'overlay' }
  },
  {
    path: '/users/:user_id/overlays/last_ten',
    name: 'OverlayLatestDonator',
    component: OverlayLatestDonator,
    meta: { layout: 'overlay' }
  },
  {
    path: '/overlays/:id',
    name: 'OverlayTipa',
    component: OverlayTipa,
    meta: { layout: 'overlay' }
  },
  {
    path: '/beta/elements',
    name: 'ElementsOverlay',
    component: ElementsOverlay,
    meta: { layout: 'overlay' }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routesOverlay
})

export default router

import Vue from 'vue'
import App from './App.vue'

import router from './router'

import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'


import OverlayLayout from './layouts/Overlay.vue'

import "animate.css/animate.min.css";
Vue.component('overlay-layout', OverlayLayout)

import VueMeta from 'vue-meta'
import ActionCableVue from 'actioncable-vue'

Vue.use(ActionCableVue, {
  debug: false,
  debugLevel: 'error',
  // debugLevel: 'error',
  // connectionUrl: store.getters['user/cableConnectionUrl'], // or function which returns a string with your JWT appended to your server URL as a query parameter
  connectImmediately: false,
  // store
})

Sentry.init({
  Vue,
  dsn: 'https://7bb9016a20904b7182352379991d414a@mon.locomotiva.info/8',
  integrations: [new Integrations.BrowserTracing({
    routingInstrumentation: Sentry.vueRouterInstrumentation(router),
  })],
  // We recommend adjusting this value in production, or using tracesSampler
  tracesSampleRate: 0.75,
  tracingOptions: {
    trackComponents: true
  }
})

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

<template>
  <div id="app">
    <transition>
      <overlay-layout>
        <router-view />
      </overlay-layout>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">
  .overlay-layout {
    background: transparent !important;
  }
</style>
